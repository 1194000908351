<template>
  <div>
    <!-- <this-header /> -->
    <!-- <div v-else> -->
    <div class="container-new pd2 pb-5">
      <div class="d-flex align-items-center">
        <router-link
          v-if="UserData.usertype
            == 'member'"
          :to="{ name: 'tanghuay-home' }"
        >
          <button class="bnt-home">
            <i class="fas fa-home" />
          </button>
        </router-link>
        <div class="txt-right bg-dark-red-3 mt-2">
          <div class="font-weight-bolder">
            <span class="">รายการหวยล่าสุด</span>
          </div>
        </div>
      </div>
      <div class="mb-2 mt-1">

        <b-col
          v-for="group_huay in LottoHeadList"
          :key="group_huay.group_id"
          md="12"
          class="mb-1 bg-dark-red-2"
        >
          <div>
            <div>
              <h4 class="hd-txt">
                {{ group_huay.group_name }}
              </h4>
              <hr>
            </div>
            <div class="row gutters-10">
              <div
                v-for="list_huay in group_huay.Lotto"
                :key="list_huay.LottoHead"
                class="col-md-6 col-lg-4"
              >
                <div
                  class="promoBox pointer"
                  data-href="/member/lottery/government"
                  @click="buyLotto(list_huay)"
                >

                  <h4>
                    <img
                      v-if="list_huay.LottoPicture"
                      :src="`https://api.hapi-lot.com/api/get/img?pathfile=${EncodeBase64(list_huay.LottoPicture)}`"
                      width="30px"
                    > {{ list_huay.LottoName }}</h4>
                  <p class="text-center mb-1">
                    <i
                      key=""
                      class="far fa-stopwatch"
                      style="margin-right: 1px;font-size:20px;"
                    />
                    <strong
                      class="text-time2 countdown text-dark"
                      data-finaldate="1663686600000"
                    >
                      &nbsp;{{ list_huay.CloseDateName
                        ? list_huay.CloseDateName : 'ปิดรับแทง' }} ชม.</strong>
                  </p>
                  <small
                    class="text-right"
                    style="position: absolute;right:0;bottom:0;color:gray;"
                  >
                    ปิดรับ : {{ list_huay.CloseDate }} น.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </div>
    <!-- <this-footer /> -->
  </div>
</template>

<script>
import { required, between, digits } from '@validations'
import {
  // BRow,
  BCol,
  //   BCard,
  VBToggle,
} from 'bootstrap-vue'
// import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ThisHeader from './component/ThisHeader.vue'
// import ThisFooter from './component/ThisFooter.vue'

export default {
  components: {
    // BCard,
    // BRow,
    BCol,
    // ThisHeader,
    // ThisFooter,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      isActive: false,
      discount: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      required,
      between,
      digits,
      show: false,
      LottoHeadList: [],
      Interval: null,
      items: [],
      userbalance: 0,
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalTime)
  },
  created() {
    this.Interval = setInterval(() => {
      // this.ShowWallet()
      this.Getlottolist()
    }, 30000)
  },
  async mounted() {
    await this.Getlottolist()
    this.ShowWallet()
    this.IntervalTime = setInterval(() => {
      this.LoopCloseTime()
    }, 1000)
  },
  methods: {
    EncodeBase64(val) {
      const encodedText = btoa(val)
      return encodedText
    },
    Getlottolist() {
      this.$http
        .post('/member/LottoList/RoundListOpen')
        .then(response => {
          this.LottoHeadList = response.data.ListLotto

          // console.log(this.LottoHeadList)
          this.LoopCloseTime()
          // this.LottoSubList = response.data.SubHead
        })
        .catch(error => console.log(error))
    },
    LoopCloseTime() {
      // eslint-disable-next-line
      for (const index_group in this.LottoHeadList) {
        const group = this.LottoHeadList[index_group]
        // eslint-disable-next-line
        for (const index in group.Lotto) {
          const Item = group.Lotto[index]
          Item.CloseDateName = this.CalDiffTime(Item.CloseDate)
          // console.log(Item.CloseDateName)
        }
      }
    },
    // eslint-disable-next-line consistent-return
    CalDiffTime(Val) {
      const DateRound = momenttz(Val).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)

      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      clearInterval(this.Interval)
      this.$router.push({ name: 'lottery' })
    },
    buyLotto(data) {
      if (momenttz(data.CloseDate).tz('Asia/Bangkok').valueOf() > momenttz().tz('Asia/Bangkok').valueOf()) {
        // eslint-disable-next-line no-underscore-dangle
        this.$router.push({ path: `/tanghuay-online/${data.LottoName}/${data.LottoHead}/${data.LottoSubHead ? data.LottoSubHead : 0}/${data.CloseDate}/${data._id}` })
      } else {
        this.$swal({
          title: 'ปิดรับแทง',
          text: `ขออภัย ${data.LottoName} ปิดรับแทงแล้ว`,
          icon: 'warning',
          confirmButtonText: 'ตกลง',
        })
      }
    },
    async ShowWallet() {
      try {
        const params = {
          UserToken: localStorage.getItem('UserToken'),
        }
        const { data: response } = await this.$http.get(
          '/seamless/wallet/show', { params },
        )
        if (response && response.success === true) {
          const Main = response.WalletData
          this.userbalance = Main.balance
          this.discount = response.discount
        } else {
          this.userbalance = 0
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style scoped>
.flex-fill {
  padding: 1px;
}

.view-rules {
  display: inline-flex;
  align-items: center;
  border: 0;
  text-align: center;
  background-color: #FCC201;
  border-radius: 13px;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 15px;

}

td {
  border: 1px solid #707070af;
  background: #fff;
  padding: 5px;
}

tr {
  margin: auto;
  width: 320px;
}

.form-css {
  border: none !important;
  border-radius: 10px !important;
}

.btns-bet-l {
  width: calc(100% - 110px);
}

.row-top-action {
  background-color: rgb(93, 173, 240) !important;
  /* background-image: url(/back_bg.svg) !important; */
}

.row-bottom-action {
  background-color: #0c2574 !important;
  /* background-image: url(/back_bg.svg) !important; */
}

@media only screen and (max-width: 768px) {
  .row-bottom-action {
    width: 100% !important;
    left: 0;
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .bet-action-bottom .bet-detail {
    width: 50%;
    border-bottom: 1px solid #7f0000;
  }
}

.bet-action-bottom .bet-detail {
  min-width: 140px;
  padding: 10px 20px;
  border-right: 1px solid #7f0000;
}

.input-bottom-bet {
  width: calc(100% - 90px);
  height: 44px;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 700;
  color: #FF6700;
  border: none;
  border-radius: 4px;
}

@media (max-width: 768px) {

  .bet-action-bottom .bet-button,
  .bet-action-bottom .bet-form {
    margin: 0 auto;
    width: 100%;
  }
}

.bet-button {
  padding: 5px 20px 15px;
  width: 360px;
  display: flex;
}

.float-left {
  float: left !important;
}

a:focus,
input {
  outline: 0;
}

.color-sub {
  color: #fff;
}

.color-white {
  color: #FFF;
}

.font-page-title {
  font-size: 20px;
}

.bet-list-item.yellow {
  color: #edad0c;
}

.bet-list-item.orange {
  color: #FF6700;
}

.bet-list-item.green {
  color: #2CA468;
}

.bet-list-item.yellow.selected,
.bet-list-item.yellow.selected:hover {
  background-color: #FCC201;
  color: #FFF;
}

.bet-list-item {
  float: left;
  width: 100%;
  border-radius: 15px;
  background-color: #FFF;
  line-height: 20px;
  color: #818181;
  font-size: 12px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, .125);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}

.font-page-title {
  font-size: 20px;
}

.bet-sm-item {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: #818181;
  text-align: center;
  background-color: #FFF;
  display: block;
}

.btn1.active {
  background: #3b5898 !important;
  color: #fff !important;
}

.button-num-cal {
  width: 100%;
  background: linear-gradient(180deg, #FFF 0, #F1F1F1 100%);
  border: none;
  margin: 0 0 10px;
  font-size: 30px;
}

.button-num-cals {
  width: 100%;
  background: linear-gradient(180deg, #424242 0, #202020 100%);
  border: none;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  margin: 0 0 10px;
  font-size: 30px;
}

.button-num-calss {
  width: 100%;
  background: linear-gradient(180deg, #424242 0, #202020 100%);
  border: none;
  font-size: 16px;
  border-radius: 4px;
  color: #fff;
  margin: 0 0 10px;
  font-size: 30px;
}

.button-action-cal,
.button-num-cal {
  box-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
  border-radius: 4px;
  text-align: center;
  height: 45px;
}

.clicked {
  background: #3b5898 !important;
  color: #fff !important;
}

.btns,
.input-2-box .text-control {
  font-weight: 700;
  padding: 10px;
  line-height: 24px;
}

.bg-cradit {
  margin: auto;
  background-color: #fff;
  color: #000;
  width: 150px;
  font-size: 0.6rem;
  border-radius: 10px;
}

.condition-top {
  background-color: #d40000;
  border-radius: 10px 10px 0px 0px;
  padding: 5px;
  color: #fff;
}

.condition-body {
  background-color: #838383;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
}

.btn-type3 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  /* border-radius: 25px; */
  font-weight: 700;
  border: 0px;
}

.btn-type2 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}

.btn-type1 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}

.active,
.btnp:focus {
  border-bottom: 4px solid #0c2574;
  color: #0c2574;
}

.header-bg-content2 {
  background-color: #fbc02d;
  border-bottom: 2px solid #0c2574;
}

.bg-side {
  background-color: #111;
  border-radius: 5px;
  padding: 2px;
  color: #fff;
}

.pdd {
  margin: auto;
  max-width: 540px;
}

hr {
  margin-top: -2px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right,
      rgba(0, 0, 0, 0),
      rgba(221, 221, 221, 0.75),
      rgba(0, 0, 0, 0));
}

p {
  line-height: 0.7;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.text-white {
  color: rgb(0, 115, 160) !important;
}

@media screen and (max-width: 768px) {
  .btn-tanghuay {
    font-size: 12px !important;
    padding-right: 4px;
  }
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-tanghuay {
  line-height: 1;
}

.action-bar.bar-white .nav-left {
  color: rgb(0, 0, 0) !important;
  font-weight: 700;
}

.action-bar.bar-white .nav-right {
  color: rgb(0, 0, 0) !important;
}
</style>

<style lang="scss" scoped>
@import url(https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css);
@import '@core/scss/vue/pages/page-auth.scss';

.form-control {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0rem;
}

.style-1 {
  .otp-input-8-field input {
    border-radius: 5;
    border: 2px solid #c50000;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.col-4,
.col-sm-6 {
  padding: 0.18rem;
  max-width: 100%;
  flex-grow: 1;
}

h4{
font-size: 16px !important;
}
</style>
